<template>
  <div>
    <location />
    <div class="latest-tourn-wrapper">
      <router-link
        class="latest-tourn-info"
        :to="{
          name: 'Condition',
          params: {
            id:
              tournamenttop.tournamentinfo &&
              tournamenttop.tournamentinfo.tournamentno,
          },
        }"
      >
        <div>
          <span class="tourn-type badge rounded-pill"
            >{{
              tournament_type(
                tournamenttop.tournamentinfo &&
                  tournamenttop.tournamentinfo.type
              )
            }}/
            {{
              tournamenttop.tournamentinfo &&
              tournamenttop.tournamentinfo.tour_rank_type | tour_rank_type
            }}</span
          >
          <h2>
            {{
              tournamenttop.tournamentinfo && tournamenttop.tournamentinfo.title
            }}
          </h2>
          <!-- <p class="info">
            {{
              tournamenttop.tournamentinfo &&
              tournamenttop.tournamentinfo.description
            }}
          </p> -->
          <div class="row row-cols-1 row-cols-sm-auto gy-1">
            <div
              class="col text-date"
              v-if="
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_private
              "
            >
              <!-- 사용자 시간 -->
              <dfn
                class="time-zone-mark badge rounded-pill bg-orange"
                title="Connect time"
                >CT</dfn
              >
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_private
                  | dateformat
              }}
              ~
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_enddate.timezone_private
                  | dateformat
              }}
            </div>
            <div
              class="col text-date"
              v-else-if="
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_common
              "
            >
              <!-- 서비스 시간 -->
              <dfn
                class="time-zone-mark badge rounded-pill bg-green"
                title="Standard time"
                >ST</dfn
              >
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_common
                  | dateformat
              }}
              ~
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_enddate.timezone_common
                  | dateformat
              }}
            </div>
          </div>
        </div>
      </router-link>
      <img
        :src="
          tournamenttop.tournamentinfo &&
          tournamenttop.tournamentinfo.tourbinary &&
          tournamenttop.tournamentinfo.tourbinary[0] &&
          tournamenttop.tournamentinfo.tourbinary[0].filepath
            | get_img(
              'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
            )
        "
      />
    </div>

    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-sm-4 row-cols-lg-6 g-2 justify-content-end"
      >
        <div class="col">
          <select v-model="searchData.pagesize">
            <option value="9" selected>
              {{ $t("filter.common.sort_by_9") }}
            </option>
            <option value="18">{{ $t("filter.common.sort_by_18") }}</option>
            <option value="27">{{ $t("filter.common.sort_by_27") }}</option>
          </select>
        </div>
        <div class="col">
          <select name="type" v-model="searchData.tourstatus_fk">
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.ready") }}</option>
            <option value="2">{{ $t("text.common.playing") }}</option>
            <option value="3">{{ $t("text.common.completed") }}</option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.tourtype_fk"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.status_public") }}</option>
            <option value="2">
              {{ $t("text.common.status_invitational") }}
            </option>
            <!-- <option value="2">매장명</option> -->
            <!-- <option value="3">{{ $t("filter.common.course") }}</option> -->
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.tour_rank_type"
          >
            <option value="-1">{{ $t("filter.common.total") }}</option>
            <option value="0">
              {{ $t("filter.common.low_gross") }} /
              {{ $t("filter.common.low_net") }}
            </option>
            <option value="1">{{ $t("filter.common.low_gross") }}</option>
            <option value="2">
              {{ $t("filter.common.low_net") }}
            </option>
            <!-- <option value="2">매장명</option> -->
            <!-- <option value="3">{{ $t("filter.common.course") }}</option> -->
          </select>
        </div>
        <div class="col-12 col-lg-4 flex-grow-1">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
              :placeholder="
                $t('alert.common.field_enter', {
                  field: $t('title.common.tournament'),
                })
              "
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <div
        v-if="total_cnt > 0"
        class="tourn-card row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-xl-5 gy-5"
      >
        <div class="col" v-for="(item, index) in items" :key="'a' + index">
          <router-link
            :to="{
              name: 'Condition',
              params: {
                id: item.tournamentinfo.tournamentno,
              },
            }"
          >
            <div class="card h-100">
              <div class="card-img-wrapper">
                <img
                  :src="
                    item.tournamentinfo &&
                    item.tournamentinfo.tourbinary &&
                    item.tournamentinfo.tourbinary[0] &&
                    item.tournamentinfo.tourbinary[0].filepath
                      | get_img(
                        'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                      )
                  "
                />
              </div>
              <div class="card-body">
                <span class="tourn-type badge rounded-pill"
                  >{{ tournament_type(item.tournamentinfo.type) }} /
                  {{
                    item.tournamentinfo.tour_rank_type | tour_rank_type
                  }}</span
                >

                <h5 class="card-title">{{ item.tournamentinfo.title }}</h5>
              </div>
              <div class="card-footer">
                <p
                  class="text-truncate text-date mb-1"
                  v-if="
                    item.tournamentinfo.timezone_startdate &&
                    item.tournamentinfo.timezone_startdate.timezone_private
                  "
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-orange"
                    title="Connect time"
                    >CT</dfn
                  >
                  {{
                    item.tournamentinfo.timezone_startdate.timezone_private
                      | dateformat
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_private
                      | dateformat
                  }}
                </p>

                <!-- 서비스 시간 -->
                <p
                  class="text-truncate text-date"
                  v-else-if="
                    item.tournamentinfo.timezone_startdate &&
                    item.tournamentinfo.timezone_startdate.timezone_common
                  "
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >
                  {{
                    item.tournamentinfo.timezone_startdate.timezone_common
                      | dateformat
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_common
                      | dateformat
                  }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div v-else>
        <table class="table board">
          <tbody>
            <tr>
              <td>{{ $t("text.common.no_data") }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TournamentSchedule",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        tourstatus_fk:
          this.$route.query.tourstatus_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourstatus_fk) ||
          0,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        tourtype_fk:
          this.$route.query.tourtype_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourtype_fk) ||
          0,
        tour_rank_type:
          this.$route.query.tour_rank_type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tour_rank_type) ||
          -1,
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          9,
      },
      page_total: 1,
      total_cnt: 0,

      items: [],
      tournamenttop: {},
      view_hide: null,
    };
  },

  methods: {
    get_tournamentlist() {
      this.view_hide = null;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_tournamentlist(this.searchData, (flag, data) => {
        this.total_cnt = data.tournamentlistcnt;
        this.page_total = Math.ceil(
          data.tournamentlistcnt / this.searchData.pagesize
        );
        this.items = data.tournamentlist;
        this.tournamenttop = data.tournamenttop;
        if (flag) {
        } else {
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchname: this.searchData.searchname,
            searchtype: this.searchData.searchtype,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_tournamentlist();
  },
  watch: {
    "searchData.tourtype_fk": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.tourstatus_fk": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.tour_rank_type": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },

    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },

    "searchData.pageno": function () {
      this.get_tournamentlist();
    },
  },
  mixins: [myMixin],
};
</script>
