<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ $t("title.membership.round_summary") }}</h3>
      <div class="row row-cols-1 row-cols-md-2 g-0 border-top-dgray">
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_score")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  summaryinfo.avgscore
                    ? null || summaryinfo.avgscore
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.max_distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  summaryinfo.maxdriver
                    ? null || summaryinfo.maxdriver + "m"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_putts_distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ summaryinfo.avggreenhit | toFixed }}m
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-6 bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.membership.swing_video_count") }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ summaryinfo.swingcnt ? null || summaryinfo.swingcnt  : "-" | comma }}
              </p>
            </div>
          </div>
        </div>         -->
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.total_rounds")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  summaryinfo.totalgamecnt
                    ? null || summaryinfo.totalgamecnt
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.handicap")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ hc_au(summaryinfo.handicap_index) }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom d-none d-md-block">
          <div class="row g-0">
            <div class="col">
              <label class="d-table-cell"></label>
            </div>
            <div class="col">
              <label class="d-table-cell"></label>
            </div>
          </div>
        </div>
      </div>
      <TermDefinition getname="score" />

      <router-link :to="{ name: 'MyRounds' }">
        <h3 class="subtitle">
          {{ $t("title.membership.recent_round")
          }}<span class="material-icons-outlined">navigate_next</span>
        </h3>
      </router-link>

      <div class="row row-cols-1 row-cols-md-2 g-0 border-top-dgray">
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.date") }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center lh-sm">
                <span
                  class="text-date"
                  v-if="
                    recentplayinfo.timezone_date &&
                    recentplayinfo.timezone_date.timezone_private
                  "
                >
                  <!-- 사용자시간 -->
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-orange"
                    title="Connect time"
                    >CT</dfn
                  >
                  {{
                    recentplayinfo.timezone_date &&
                    recentplayinfo.timezone_date.timezone_private | dateformat
                  }}
                </span>

                <!-- 서비스 시간 -->
                <span
                  class="text-date"
                  v-else-if="
                    recentplayinfo.timezone_date &&
                    recentplayinfo.timezone_date.timezone_common
                  "
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >
                  {{
                    recentplayinfo.timezone_date &&
                    recentplayinfo.timezone_date.timezone_common | dateformat
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0 h-100">
            <div class="col bg-gray row g-0 align-items-center h-100">
              <label class="d-table-cell">{{
                $t("title.common.course")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  recentplayinfo.coursename
                    ? null || recentplayinfo.coursename
                    : "-"
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.score") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{ Math.sign(recentplayinfo.score) > 0 ? "+" : ""
                }}{{ recentplayinfo.score }}
                <!-- {{ recentplayinfo.score ? null || recentplayinfo.score  : "-" | comma }}               -->
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.shot") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.shot
                    ? null || recentplayinfo.shot
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.max_distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  recentplayinfo.maxdriver
                    ? null || recentplayinfo.maxdriver + "m"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  recentplayinfo.avgdriver
                    ? null || recentplayinfo.avgdriver + "m"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.fareway_hit")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  recentplayinfo.farewayrate
                    ? null || recentplayinfo.farewayrate + "%"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.par_save_rate")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{
                  recentplayinfo.parsaverate
                    ? null || recentplayinfo.parsaverate + "%"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.gir") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.greenrate
                    ? null || recentplayinfo.greenrate + "%"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_putts_distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ summaryinfo.avggreenhit | toFixed }}m
              </p>
            </div>
          </div>
        </div>
      </div>
      <TermDefinition getname="score" />

      <!-- <router-link :to="{ name: 'MySwing' }">
        <h3 class="subtitle">
          {{ $t("title.membership.recent_swing_video") }}<span class="material-icons-outlined">navigate_next</span>
        </h3>
      </router-link> -->
      <!-- <table class="table board" id="dataTable" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell text-center">{{ $t("title.membership.swing_video_count") }}</th>
            <th class="col-auto d-lg-table-cell text-center">{{ $t("title.common.location") }}</th>
            <th class="col-auto d-none d-lg-table-cell text-center">{{ $t("title.common.date") }}</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>

        <tbody v-if="swingdata_yn">
          <tr class="btn1" @click="toggle_tr(1)">
            <td class="col-auto d-lg-table-cell">
              <router-link
                :to="{
                  name: 'MySwingView',
                  params: { id: swinginfo.swingno },
                }"
                class="position-relative video-box"
              >
                <video
                  :src="swinginfo.path + '/' + swinginfo.swingmovie"
                ></video>
                <div class="mask"></div>
                <span class="icon-play"></span>
              </router-link>
            </td>
            <td class="col-auto d-lg-table-cell text-center shop-name">
              {{ swinginfo.shopname }}
            </td>
            <td class="col-auto d-none d-lg-table-cell text-center text-date">
              {{ swinginfo.date | GMTtuUTCTIME }}
            </td>
            <td class="td-arrow">
              <img
                src="/img/arrow_none_collapsed.png"
                :class="{ 'btn-toggle-arrow': view_hide === 1 }"
              />
            </td>
          </tr>
          <tr
            class="d-lg-none"
            :class="{ show: view_hide === 1 }"
            v-if="view_hide === 1"
          >
            <td colspan="3">
              <span class="d-inline-block me-2 fw-bold text-black">
                {{ $t("title.common.date") }}
              </span>
              <span class="text-date">
                {{ swinginfo.date | GMTtuUTCTIME }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";
import "@/filter/common";
import TermDefinition from "@/components/TermDefinition";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MySummary",
  components: {
    location,
    TermDefinition,
  },
  data() {
    return {
      summaryinfo: [],
      recentplayinfo: [],
      swinginfo: [],
      swingdata_yn: true,
      view_hide: null,
    };
  },
  methods: {
    get_membersummary() {
      ax.get_membersummary((flag, data, code) => {
        console.log(flag, data);
        if (flag) {
          this.summaryinfo = data.summaryinfo;
          this.recentplayinfo = data.recentplayinfo;
          this.swinginfo = data.swinginfo;
          if (data.swinginfo.length <= 0) {
            this.swingdata_yn = false;
            return false;
          } else {
            this.swingdata_yn = true;
          }
        } else {
          if (code == "300") {
            alert(data.message);
            this.$notify({
              // (optional)
              // Name of the notification holder
              group: "alert",

              // (optional)
              // Class that will be assigned to the notification
              type: "warn",

              // (optional)
              // Title (will be wrapped in div.notification-title)

              // Content (will be wrapped in div.notification-content)
              text: data.message,

              // (optional)
              // Overrides default/provided duration
              duration: 5000,

              // (optional)
              // Overrides default/provided animation speed
              speed: 1000,

              // (optional)
              // Data object that can be used in your template
            });
          } else alert(data.message);
          if (code === 401) {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
        }
      });
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membersummary();
  },
  mixins: [myMixin],
};
</script>
